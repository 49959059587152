import React from 'react';
import './StoreCard.css';
import {useTelegram} from "../../hooks/useTelegram";

const StoreCard = ({itemID, title,level, price, setItems, backendURL, setMoney, currentMoneyPerHour, setMoneyPerHour}) => {
    const {tg} = useTelegram();

    const onAddHandler = () => {
        const fetchData = async () => {
            const id = tg.initDataUnsafe?.user?.id;
            const username = tg.initDataUnsafe?.user?.username;
            const response = await fetch(`${backendURL}/buy-item?id=${id}&username=${username}&item=${itemID}`);
            const data = await response.json();
            setItems(Object.values(data));
        };
        fetchData();
        getMoney()
        getMoneyPerHour();
    }

    const getMoney = async () => {
        let tg = window.Telegram.WebApp;
        const username = tg.initDataUnsafe?.user?.username;
        const userID = tg.initDataUnsafe?.user?.id;

        try {
            const response = await fetch(`${backendURL}/get-money?id=${userID}&username=${username}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMoney(data.money);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const getMoneyPerHour = async () => {
        let tg = window.Telegram.WebApp;
        const username = tg.initDataUnsafe?.user?.username;
        const userID = tg.initDataUnsafe?.user?.id;

        try {
            const response = await fetch(`${backendURL}/get-money-per-hour?id=${userID}&username=${username}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMoneyPerHour(data.money);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <div className="store-card">
            <div className="store-card__top">
                <div className="store-card__img"/>
                <div className="store-card__right">
                    <div className="store-card__title">{title}</div>
                    <div className="store-card_profit">Profit per hour</div>
                    <div className="store-card_price">{currentMoneyPerHour} coin</div>
                </div>
            </div>
            <div className="store-card__bot">
                <div className="store-card__level">{level} lvl</div>
                <div className="store-card__buy">
                    <button className="add-btn" onClick={onAddHandler}>
                        {price} coin
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StoreCard;
