import React from 'react';
import './Footer.css';

const Footer = ({page, setPage}) => {
    return (
        <div className={'footer'}>
            <button className={`footer-button ${page === 0 ? 'active' : ''}`} onClick={()=>{setPage(0)}}>Main</button>
            <button className={`footer-button ${page === 1 ? 'active' : ''}`} onClick={()=>{setPage(1)}}>Store</button>
        </div>
    );
};

export default Footer;
