import React, {useEffect, useState} from 'react';
import './Store.css';
import StoreCard from "../StoreCard/StoreCard";
import {useTelegram} from "../../hooks/useTelegram";

const Store = ({backendURL, setMoney, setMoneyPerHour}) => {
    const {tg} = useTelegram();
    const [items, setItems] = useState([]);

    // MOCK
    /*const mockData = {
        "0": {
            "title": "Процессор",
            "level": 1,
            "description": "Описание 1",
            "basePrice": 8000,
            "coefficientPrice": 1.2,
            "currentPrice": 9600,
            "moneyPerHour": 100,
            "coefficientMoneyPerHour": 1.8,
            "currentMoneyPerHour": 180
        },
        "1": {
            "title": "Материнская плата",
            "level": 1,
            "description": "Описание 2",
            "basePrice": 10000,
            "coefficientPrice": 1.2,
            "currentPrice": 12000,
            "moneyPerHour": 120,
            "coefficientMoneyPerHour": 1.3,
            "currentMoneyPerHour": 156
        },
        "2": {
            "title": "Видеокарта",
            "level": 1,
            "description": "Описание 3",
            "basePrice": 25000,
            "coefficientPrice": 1.2,
            "currentPrice": 30000,
            "moneyPerHour": 130,
            "coefficientMoneyPerHour": 1.9,
            "currentMoneyPerHour": 247
        },
        "3": {
            "title": "Оперативная память",
            "level": 1,
            "description": "Описание 4",
            "basePrice": 4000,
            "coefficientPrice": 1.2,
            "currentPrice": 4800,
            "moneyPerHour": 140,
            "coefficientMoneyPerHour": 1.4,
            "currentMoneyPerHour": 196
        },
        "4": {
            "title": "Корпус",
            "level": 1,
            "description": "Описание 5",
            "basePrice": 3000,
            "coefficientPrice": 1.2,
            "currentPrice": 3600,
            "moneyPerHour": 100,
            "coefficientMoneyPerHour": 1.1,
            "currentMoneyPerHour": 110
        },
        "5": {
            "title": "Блок питания",
            "level": 1,
            "description": "Описание 6",
            "basePrice": 4000,
            "coefficientPrice": 1.2,
            "currentPrice": 4800,
            "moneyPerHour": 150,
            "coefficientMoneyPerHour": 1.2,
            "currentMoneyPerHour": 180
        },
        "6": {
            "title": "Охлаждение процессора",
            "level": 1,
            "description": "Описание 7",
            "basePrice": 500,
            "coefficientPrice": 1.2,
            "currentPrice": 600,
            "moneyPerHour": 160,
            "coefficientMoneyPerHour": 1.1,
            "currentMoneyPerHour": 176
        },
        "7": {
            "title": "Жесткий диск",
            "level": 1,
            "description": "Описание 8",
            "basePrice": 1000,
            "coefficientPrice": 1.2,
            "currentPrice": 1200,
            "moneyPerHour": 200,
            "coefficientMoneyPerHour": 1,
            "currentMoneyPerHour": 200
        },
        "8": {
            "title": "Твердотельный накопитель",
            "level": 1,
            "description": "Описание 9",
            "basePrice": 1500,
            "coefficientPrice": 1.2,
            "currentPrice": 1800,
            "moneyPerHour": 180,
            "coefficientMoneyPerHour": 1.6,
            "currentMoneyPerHour": 288
        }
    }*/

    useEffect(() => {
        const fetchData = async () => {
            const id = tg.initDataUnsafe?.user?.id;
            const username = tg.initDataUnsafe?.user?.username;
            const response = await fetch(`${backendURL}/get-items?id=${id}&username=${username}`);
            const data = await response.json();
            setItems(Object.values(data));
        };
        fetchData();

        //setItems([...Object.values(mockData)]);
    }, []);

    return (
        <div className="list">
            {items.map((item, index) => (
                <StoreCard itemID={index} price={item.currentPrice} title={item.title} level={item.level} setItems={(items)=>{setItems(items)}} backendURL={backendURL} setMoney={(money)=>{setMoney(money)}} currentMoneyPerHour={item.currentMoneyPerHour} setMoneyPerHour={(moneyPerHour)=>{setMoneyPerHour(moneyPerHour)}}/>
            ))}
        </div>
    );
};

export default Store;
