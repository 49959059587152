import React from 'react';
import './Main.css';

const moneyPerClick = 2

const Main = ({setMoney, backendURL}) => {
    const addMoney = async (event) => {
        let tg = window.Telegram.WebApp;
        const username = tg.initDataUnsafe?.user?.username;

        try {
            const response = await fetch(`${backendURL}/add-money?id=${tg.initDataUnsafe?.user?.id}&username=${username}&money=${moneyPerClick}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setMoney(data.money);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <div className="main">
            <button className="tap-button" onClick={event => addMoney(event)}></button>
        </div>
    );
};

export default Main;
