import React, {useEffect, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
import './App.css';
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Footer from "./components/Footer/Footer";
import Store from "./components/Store/Store";

const backendURL = "https://backend.sometruma.ru"

const TelegramUserInfo = () => {
    const {tg} = useTelegram();
	const [money, setMoney] = useState(0);
	const [moneyPerHour, setMoneyPerHour] = useState(0);
	const [page, setPage] = useState(0);

    useEffect(() => {
        tg.ready();
		getMoney();
		getMoneyPerHour();
		tg.expand();

		// Запускаем интервал
		const intervalId = setInterval(() => {
			getMoney();
		}, 60000); // 60000 миллисекунд = 1 минута

		// Очищаем интервал при размонтировании компонента
		return () => clearInterval(intervalId);
    }, [])

	const getMoney = async () => {
		let tg = window.Telegram.WebApp;
		const username = tg.initDataUnsafe?.user?.username;

		try {
			const response = await fetch(`${backendURL}/get-money?id=${tg.initDataUnsafe?.user?.id}&username=${username}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			setMoney(data.money);
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error);
		}
	};

	const getMoneyPerHour = async () => {
		let tg = window.Telegram.WebApp;
		const username = tg.initDataUnsafe?.user?.username;

		try {
			const response = await fetch(`${backendURL}/get-money-per-hour?id=${tg.initDataUnsafe?.user?.id}&username=${username}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			setMoneyPerHour(data.money);
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error);
		}
	};


	return (
		<div className="container">
			<Header className="header" money={money} moneyPerHour={moneyPerHour}/>
			{page === 0 ? (
				<Main className="main" money={money} setMoney={(money)=>{setMoney(money)}} backendURL={backendURL} />
			) : (
				page === 1 && <Store className="store" backendURL={backendURL} setMoney={(money)=>{setMoney(money)}} setMoneyPerHour={(moneyPerHour)=>{setMoneyPerHour(moneyPerHour)}}/>
			)}
			<Footer className="footer" page={page} setPage={(page)=>{setPage(page)}} />
		</div>
	);
};

export default TelegramUserInfo;
