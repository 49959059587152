import React from 'react';
import {useTelegram} from "../../hooks/useTelegram";
import './Header.css';

const Header = ({money, moneyPerHour}) => {
    const {user} = useTelegram();

    return (
        <div className="header">
            <div className="header__username">
                <div className="header__text">Username</div>
                <div className="header__username">{user?.username}</div>
            </div>
            <div className="header__moneyPerHour">
                <div className="header__text">Money per hour</div>
                <div className="header__moneyPerHour">{moneyPerHour} coin</div>
            </div>
            <div className="header__money">
                <div className="header__text">Balance</div>
                <div className="header__money"><p>{money} coin</p></div>
            </div>
        </div>
    );
};

export default Header;